// strings.js

const strings = {
    STRIPE_DEV_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/test_aEU7tv9BjcRX3eg00K", // vls
    STRIPE_PROD_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/00geVN0t03xcebeaFn", // vls

    STRIPE_SUBSCRIPTION_MANAGEMENT_URL: "https://billing.stripe.com/p/login/28ocP200L31J9gs7ss",
    SUBSCRIPTION_STATUS_API_URL: "https://funquiz.app/modular2/subscription-status",
    
    GENERIC_PAGE_TITLE: "Video Lip Sync 📽",
    HOME_PAGE_TITLE: "Home - Video Lip Sync 📽",
    DASHBOARD_PAGE_TITLE: "Home - Video Lip Sync 📽",
    WELCOME_MESSAGE_H1_TITLE: "Welcome to Video Lip Sync 📽",
    FX_DESCRIPTION_MESSAGE: "This f(x) can be used to create lipsync videos, choose a video, choose an audio and then press the 'Submit Input' button below to get your result!",
    
    MAIN_API_NAME: "video_lip_sync_ai_1",
    MAIN_API_URL: "https://funquiz.app/modular2/video-lip-sync-ai-1",

    PROD_CLERK_PUB_KEY: "pk_live_Y2xlcmsueW9sbGVnLmNvbSQ",
    DEV_CLERK_PUB_KEY: "pk_test_aGFybWxlc3MtYmVlLTY5LmNsZXJrLmFjY291bnRzLmRldiQ",

    // ... add more strings as needed
};



export default strings;